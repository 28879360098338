import D_A from "../../assets/cards/D_A_NEW.png"
import D_2 from "../../assets/cards/D_2_NEW.png"

import H_A from "../../assets/cards/H_A_NEW.png"
import H_Q from "../../assets/cards/H_Q_NEW.png"
import S_10 from "../../assets/cards/S_10_NEW.png"
import S_K from "../../assets/cards/S_K_NEW.png"


import { useNavigate } from "react-router-dom"

import { setStartTime } from "@services/performanceMetricApi"
import { setMetric } from "@redux/slices/metricSlice"
import { useDispatch } from "react-redux"
import { GAME_MODES } from "@constants/GameMode"
import { setGameMode } from "@redux/slices/actionsSlice"
// import NewLogoWithText from "../../assets/main/new-logo-with-text.png"
import NewLogoWithText from "../../assets/main/newLogoWithText.png"
import { RootState } from "@redux/store"
import { useSelector } from "react-redux"

const SelectGameMode = () => {
  let navigate = useNavigate()
  const dispatch = useDispatch()

  const { user } = useSelector((state: RootState) => state.auth)

  const handleStart = async (gameMode: string) => {
    try {
      dispatch(setGameMode(gameMode))
      navigate("/main")
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <div className="flex flex-col justify-center flex-1 min-h-full px-6 lg:px-8">
        <div className="mx-auto relative w-[1024px]">
          <img
            src={NewLogoWithText}
            className="max-w-full h-auto mx-auto"
            alt="new main logo"
            style={{ height: "20%" }}
          />
          {user?.scriptMode?.hasScriptModeFirst && <div className="flex items-center justify-between py-2">
            <label htmlFor="userId" className="text-[55px] text-[#000000]">
              No Limit Hold Em:
            </label>
            <div
              className="w-[325px] h-[200px] border-[8px] hover:border-[#FF9447] border-[#FFFFFF] rounded-[30px] relative cursor-pointer"
              onClick={() => {
                handleStart(GAME_MODES.NLHE)
              }}
            >
              <img
                src={D_A}
                className="origin-center rotate-[356deg] absolute w-[114px] h-[160px] left-[63px] top-[10px]"
                alt="D_A"
              />
              <img
                src={D_2}
                className="origin-center rotate-[0deg] absolute w-[114px] h-[160px] left-[143px] top-[16px]"
                alt="D_A"
              />
            </div>
          </div>}
          {user?.scriptMode?.hasScriptModeThird && <div className="flex items-center justify-between py-2">
            <label htmlFor="userId" className="text-[55px] text-[#000000]">
              PLO Tournament:
            </label>
            <div
              className="w-[325px] h-[206px] border-[8px] hover:border-[#FF9447] border-[#FFFFFF] rounded-[30px] relative cursor-pointer"
              onClick={() => {
                handleStart(GAME_MODES.PLO_TOURNAMENT)
              }}
            >
              <img
                src={H_A}
                className="origin-center rotate-[356deg] absolute w-[114px] h-[160px] left-[19px] top-[22px]"
                alt="H_A"
              />
              <img
                src={H_Q}
                className="origin-center rotate-[355deg] absolute w-[114px] h-[160px] left-[79px] top-[7px]"
                alt="H_Q"
              />
              <img
                src={S_10}
                className="origin-center rotate-[0deg] absolute w-[114px] h-[160px] left-[129px] top-[10px]"
                alt="S_10"
              />
              <img
                src={S_K}
                className="origin-center rotate-[6deg] absolute w-[114px] h-[160px] left-[180px] top-[24px]"
                alt="S_K"
              />
            </div>
          </div>}
          {user?.scriptMode?.hasScriptModeSecond && <div className="flex items-center justify-between py-2">
            <label htmlFor="userId" className="text-[55px] text-[#000000]">
              Pot Limit Omaha:
            </label>
            <div
              className="w-[325px] h-[206px] border-[8px] hover:border-[#FF9447] border-[#FFFFFF] rounded-[30px] relative cursor-pointer"
              onClick={() => {
                handleStart(GAME_MODES.PLO)
              }}
            >
              <img
                src={H_A}
                className="origin-center rotate-[356deg] absolute w-[114px] h-[160px] left-[19px] top-[22px]"
                alt="H_A"
              />
              <img
                src={H_Q}
                className="origin-center rotate-[355deg] absolute w-[114px] h-[160px] left-[79px] top-[7px]"
                alt="H_Q"
              />
              <img
                src={S_10}
                className="origin-center rotate-[0deg] absolute w-[114px] h-[160px] left-[129px] top-[10px]"
                alt="S_10"
              />
              <img
                src={S_K}
                className="origin-center rotate-[6deg] absolute w-[114px] h-[160px] left-[180px] top-[24px]"
                alt="S_K"
              />
            </div>
          </div>}
        </div>
      </div>
    </>
  )
}

export default SelectGameMode
